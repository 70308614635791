<template>
  <v-container fill-height>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        lg="4"
        md="5"
      >
        <v-card outlined>
          <v-img
            class="mt-5 mb-1"
            width="96px"
            src="https://admin.salescloud.is/sites/all/themes/flatkit/logo_login.png"
            style="margin: 0 auto;"
          />
          <v-card-title style="text-align: center !important; display: block;">
            {{ $t('resetPassword') }}
          </v-card-title>
          <v-card-subtitle class="text-center">
            {{ $t('noWorriesLetsGetThisSorted') }}
          </v-card-subtitle>
          <v-card-text class="pb-0">
            <v-text-field
              :disabled="processing"
              autofocus
              name="email"
              outlined
              type="email"
              :label="$t('email')"
              v-model="email"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="processing"
              :disabled="processing"
              large
              block
              @click="reset"
              color="primary"
            >
              {{ $t('resetPassword') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-btn
          small
          class="mt-2"
          block
          text
          to="/login"
        >
          {{ $t('login') }}?
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            processing: false
        }
    },
    methods: {
        reset() {
            this.processing = true
            this.$store.dispatch('resetPassword', {
                email: this.email
            }).finally(() => {
                this.processing = false
            })
        }
    }
}
</script>